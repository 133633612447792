import { CardStackPlusIcon, CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ColumnDef, FilterFnOption, Row, SortingFnOption, createColumnHelper } from '@tanstack/react-table'
import { getQueryKey } from '@trpc/react-query'
import { AppSelectFormfield } from 'components/AppTextfield'
import { BasicButton } from 'components/BasicButton'
import SparklineChart from 'components/charts/SparklineChart'
import { InputCheckbox } from 'components/editable-table/CheckBox'
import { columnType, renderEditableCell } from 'components/editable-tanstack-table/EditableTanstackTable'
import _ from 'lodash'
import { ScrollText } from 'lucide-react'
import { safeCastToNumber } from 'pages/cashSimulator/CashSimulatorContext'
import CompanyStatChart2 from 'pages/company/massiveEnrichedCompany/CompanyStatChart2'
import CompanyStatChartTimeFooter, {
  useChartTimeFooter,
} from 'pages/company/massiveEnrichedCompany/CompanyStatChartTimeFooter'
import { MASSIVE_DEV_URL } from 'plural-shared/constants'
import { numberToAbbreviatedDollarString, numberToAbbreviatedString, numberToReadableString } from 'plural-shared/utils'
import { PluralLink, usePluralNavigation } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { Optional, isSome } from 'safety'
import { getFundingRounds, processFundingRound } from 'several/actions/FundingRounds'
import DropdownSearch from 'several/components/DropdownSearch'
import { hackyEditorIds } from 'several/hooks/FundingRound'
import { MasterFundingRound, RawMasterFundingRound } from 'several/models/FundingRounds'
import { RouterOutputs, trpc } from 'utils/trpc'
import SheetPage from './AdminSheetPage'

export type MassiveCompany = {
  companyName: string
  companyDomain: string
  foundingDate: string
  allHeadcountStats: Array<{
    companyId: string
    statsDate: string
    count: number
    adjustedCount: number
  }>
  allOpenRoleStats: Array<{
    companyId: string
    statsDate: string
    count: number
    adjustedCount: number
  }>
  latestOpenRoleCount: number
  latestHeadcount: number
  location: string
  cbCompanyDescription: string
  sixMonthsOverallHeadcountValue: number | undefined
  sixMonthsOverallHeadcountGrowthRate: number | undefined
  sixMonthsOverallOpenRoleValue: number | undefined
  sixMonthsOverallOpenRoleGrowthRate: number | undefined
  oneYearOverallHeadcountValue: number | undefined
  oneYearOverallHeadcountGrowthRate: number | undefined
  oneYearOverallOpenRoleValue: number | undefined
  oneYearOverallOpenRoleGrowthRate: number | undefined
  twoYearsOverallHeadcountValue: number | undefined
  twoYearsOverallHeadcountGrowthRate: number | undefined
  twoYearsOverallOpenRoleValue: number | undefined
  twoYearsOverallOpenRoleGrowthRate: number | undefined
  lastFundingRoundAnnouncementDate: Date | undefined
  lastFundingRoundAmount: number | undefined
  lastFundingRoundClosedDate: Date | undefined
  companyS3LogoUrl: string | undefined
  companyId: string
  createdAt: string
  fundingRounds: MasterFundingRound[]
  totalAmountRaised: number | undefined
  lastRoundPrice: number | undefined
  lastRoundValuation: number | undefined
  lastKnownPrice: number | undefined
  lastKnownValuation: number | undefined
  isPublic: boolean | undefined
  operatingStatus: 'Active' | 'Closed' | null
}

export type MassiveCompanySearchData = {
  companyId: string
  companyName: string
  companyDomain: string
  latestHeadcount: number
  companyS3LogoUrl: string | undefined
}

export function getNumber(value: any): number | undefined {
  if (value === undefined) return undefined
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    const parsed = parseInt(value)
    return isNaN(parsed) ? undefined : parsed
  }
  return undefined
}
export type RawMassiveCompany = {
  operatingStatus: 'Active' | 'Closed' | null
  companyName: string
  companyDomain: string
  foundingDate: string
  latestOpenRoleCount: number
  latestHeadcount: number
  allHeadcountStats: Array<{
    companyId: string
    statsDate: string
    count: number
    adjustedCount: number
  }>
  allOpenRoleStats: Array<{
    companyId: string
    statsDate: string
    count: number
    adjustedCount: number
  }>
  location: string
  cbCompanyDescription: string
  sixMonthsOverallHeadcountValue: number | string
  sixMonthsOverallHeadcountGrowthRate: string
  sixMonthsOverallOpenRoleValue: number | string
  sixMonthsOverallOpenRoleGrowthRate: string
  oneYearOverallHeadcountValue: number | string
  oneYearOverallHeadcountGrowthRate: string
  oneYearOverallOpenRoleValue: number | string
  oneYearOverallOpenRoleGrowthRate: string
  twoYearsOverallHeadcountValue: number | string
  twoYearsOverallHeadcountGrowthRate: string
  twoYearsOverallOpenRoleValue: number | string
  twoYearsOverallOpenRoleGrowthRate: string
  fundingRounds: RawMasterFundingRound[]
  companyS3LogoUrl: string | undefined
  companyId: string
  createdAt: string
  totalAmountRaised: string
  lastRoundAnnouncedDate: string
  lastRoundClosedDate: string
  lastRoundAmount: string
  lastRoundPrice: string
  lastKnownPrice: string
  lastKnownValuation: string
  lastRoundValuation: string
  isPublic?: boolean
}
export function processRawMassiveCompany(company: RawMassiveCompany): MassiveCompany {
  return {
    companyId: company.companyId,
    companyName: company.companyName,
    companyDomain: company.companyDomain,
    foundingDate: company.foundingDate,
    latestOpenRoleCount: company.latestOpenRoleCount,
    latestHeadcount: company.latestHeadcount,
    location: company.location,
    allHeadcountStats: company.allHeadcountStats,
    cbCompanyDescription: company.cbCompanyDescription,
    sixMonthsOverallHeadcountValue: getNumber(company.sixMonthsOverallHeadcountValue),
    sixMonthsOverallHeadcountGrowthRate: parsePercentage(company.sixMonthsOverallHeadcountGrowthRate),
    sixMonthsOverallOpenRoleValue: getNumber(company.sixMonthsOverallOpenRoleValue),
    sixMonthsOverallOpenRoleGrowthRate: parsePercentage(company.sixMonthsOverallOpenRoleGrowthRate),
    oneYearOverallHeadcountValue: getNumber(company.oneYearOverallHeadcountValue),
    oneYearOverallHeadcountGrowthRate: parsePercentage(company.oneYearOverallHeadcountGrowthRate),
    oneYearOverallOpenRoleValue: getNumber(company.oneYearOverallOpenRoleValue),
    oneYearOverallOpenRoleGrowthRate: parsePercentage(company.oneYearOverallOpenRoleGrowthRate),
    twoYearsOverallHeadcountValue: getNumber(company.twoYearsOverallHeadcountValue),
    twoYearsOverallHeadcountGrowthRate: parsePercentage(company.twoYearsOverallHeadcountGrowthRate),
    twoYearsOverallOpenRoleValue: getNumber(company.twoYearsOverallOpenRoleValue),
    twoYearsOverallOpenRoleGrowthRate: parsePercentage(company.twoYearsOverallOpenRoleGrowthRate),
    lastFundingRoundAnnouncementDate: company.lastRoundAnnouncedDate
      ? new Date(company.lastRoundAnnouncedDate)
      : undefined,
    lastFundingRoundAmount: getNumber(company.lastRoundAmount),
    lastFundingRoundClosedDate: company.lastRoundClosedDate ? new Date(company.lastRoundClosedDate) : undefined,
    companyS3LogoUrl: company.companyS3LogoUrl,
    createdAt: company.createdAt,
    fundingRounds: company.fundingRounds.map(processFundingRound),
    lastKnownPrice: getNumber(company.lastKnownPrice),
    lastKnownValuation: getNumber(company.lastKnownValuation),
    lastRoundPrice: getNumber(company.lastRoundPrice),
    lastRoundValuation: getNumber(company.lastRoundValuation),
    totalAmountRaised: getNumber(company.totalAmountRaised),
    isPublic: company.isPublic,
    operatingStatus: company.operatingStatus,
    allOpenRoleStats: company.allOpenRoleStats,
  }
}

export function processRawMassiveCompanyForSearch(company: RawMassiveCompany): MassiveCompanySearchData {
  return {
    companyId: company.companyId,
    companyName: company.companyName,
    companyDomain: company.companyDomain,
    latestHeadcount: company.latestHeadcount,
    companyS3LogoUrl: company.companyS3LogoUrl,
  }
}

function getLatestRoundDate(
  round: RawMasterFundingRound,
  type: 'announced' | 'closed' | 'all' = 'all',
): Date | undefined {
  if (!round) {
    return undefined
  }
  let latestDate: Date | undefined = undefined
  function setRoundDate(round: RawMasterFundingRound) {
    if (round.announcedDate && type !== 'closed') {
      const announcedDate = new Date(round.announcedDate)
      if (announcedDate.getTime() > (latestDate ?? new Date('01-01-1970')).getTime()) {
        latestDate = announcedDate
      }
    }
    if (round.closedDate && type !== 'announced') {
      const closedDate = new Date(round.closedDate)
      if (closedDate.getTime() > (latestDate ?? new Date('01-01-1970')).getTime()) {
        latestDate = closedDate
      }
    }
  }
  setRoundDate(round)
  for (const subRound of round.subRows ?? []) {
    setRoundDate(subRound)
  }
  return latestDate
}
export async function getCompanies(): Promise<MassiveCompany[]> {
  const response = await fetch(`${MASSIVE_DEV_URL}/api/get-company-profile`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
    },
    body: JSON.stringify({
      companies: [],
    }),
  })
  let companiesObj = await response.json()
  let rawMassiveCompanies: RawMassiveCompany[] = []
  for (const cid in companiesObj ?? {}) {
    rawMassiveCompanies.push(companiesObj[cid])
  }
  return rawMassiveCompanies.map(processRawMassiveCompany)
}

export async function getSearchableCompanies(): Promise<MassiveCompanySearchData[]> {
  let response = await fetch(`${MASSIVE_DEV_URL}/api/get-company-search-data`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
    },
    body: null,
  })
  if (response.status == 404) {
    const response = await fetch(`${MASSIVE_DEV_URL}/api/get-company-profile`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
      },
      body: JSON.stringify({
        companies: [],
      }),
    })
    let companiesObj = await response.json()
    let rawMassiveCompanies: RawMassiveCompany[] = []
    for (const cid in companiesObj ?? {}) {
      rawMassiveCompanies.push(companiesObj[cid])
    }
    return rawMassiveCompanies.map(processRawMassiveCompanyForSearch)
  }

  let companiesObj = await response.json()

  const massiveCompanySearchData: MassiveCompanySearchData[] = []
  for (const cid in companiesObj ?? {}) {
    massiveCompanySearchData.push(companiesObj[cid])
  }
  return massiveCompanySearchData
}

export const cellClassName = 'max-w-full truncate p-2 h-full'
export type RouterUser = RouterOutputs['user']['getSelfWithSheets']

export default function AdminCompanySheetPage(props: { isLoading?: boolean }) {
  let columnHelper = createColumnHelper<MassiveCompany>()
  let companiesQuery = useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
  })
  const companies: MassiveCompany[] = companiesQuery.data ?? []
  let queryClient = useQueryClient()

  let addToListMutation = trpc.sheetRouter.addCompaniesToSheet.useMutation()
  const { isLoading: isAuthLoading } = usePluralAuth()
  let userQuery = trpc.user.getSelfWithSheets
  let user: RouterUser | undefined = userQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })?.data

  let isEditor = hackyEditorIds.includes(user?.id ?? -1)

  let companySheetsToAdd: any[] =
    user?.userToSheets
      .filter((utr: any) => utr.sheet.entityType === 'COMPANY' && (utr.role === 'OWNER' || utr.role === 'EDITOR'))
      .map((utr: any) => utr.sheet) ?? []

  let createFloatingFooterButtons: (selectedRows: any, deselectAll: () => void) => React.ReactNode = useMemo(() => {
    return (selectedRows, deselectAll) => {
      return (
        <DropdownSearch
          options={companySheetsToAdd.map((sheet: any) => {
            return {
              label: sheet.name,
              value: sheet.id.toString(),
              startNode: <ScrollText size={15} key={sheet.id} />,
            }
          })}
          onSelectOption={async (option) => {
            await addToListMutation.mutateAsync({
              sheetId: parseInt(option.value),
              companyValues: selectedRows.map((row: any) => row.original.companyName),
            })
            let sheetQueryKey = getQueryKey(trpc.sheetRouter.getSheet, parseInt(option.value), 'query')
            queryClient.invalidateQueries(sheetQueryKey)
            deselectAll()
          }}
          trigger={
            <BasicButton variant={'gray'} attioStyle className="flex items-center gap-1" onClick={async () => { }}>
              <CardStackPlusIcon />
              Add to List
            </BasicButton>
          }
        />
      )
    }
  }, [companySheetsToAdd])
  const prefetchFundingRounds = async () => {
    await queryClient.prefetchQuery({
      queryKey: ['fundingRounds'],
      queryFn: getFundingRounds(''),
    })
  }
  useEffect(() => {
    prefetchFundingRounds()
  }, [])

  const utils = trpc.useContext()

  const prefetchCompanyData = useCallback(
    _.debounce(async (companyId) => {
      await utils.companies.getEnrichedCompanyData.prefetch(companyId)
    }, 300),
    [],
  )
  let defaultCellFn = (cell: any) => {
    const initialValue = cell.getValue()
    let _column = cell.column.columnDef.meta!
    return renderEditableCell(
      initialValue,
      _column as unknown as columnType<MassiveCompany, any>,
      cell.row.original,
      (row) => row.companyId,
      '#FAF9FF',
    )
  }

  let updatePublicMutation = trpc.company.updateMassiveCompanyData.useMutation({
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['companies'], (oldData: MassiveCompany[] | undefined) => {
        if (!oldData) return oldData
        let newData = [...oldData]
        let companyIdx = newData.findIndex((company) => company.companyId === variables.companyId)
        if (companyIdx !== -1) {
          newData[companyIdx] = { ...newData[companyIdx], isPublic: variables.isPublic }
        }
        return newData
      })
    },
  })
  let columns: ColumnDef<MassiveCompany>[] = [
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: 'Company',
      enableGlobalFilter: true,
      minSize: 200,
      meta: {
        selectAllOptions: true,
        className: 'z-30 sticky left-0 bg-white',
        headerClassName: 'z-40 sticky left-0 bg-white',
        isImmovable: true,
        isUnhideable: true,
        singleSelectPlaceholder: 'Search for a company',
        singleSelectOptions: companies.map((company, index) => ({
          label: company.companyName,
          // CHANGE TO ID WHEN WE CAN
          value: company.companyName,
          index,
          style: {
            padding: '10px',
          },
          startNode: (
            <CompanyAvatar
              logo={company.companyS3LogoUrl}
              alt={company.companyName}
              altLetter={company.companyName.toUpperCase()}
              size={20}
            />
          ),
          endNode: <div className="font-gray-500 text-xs font-light">Headcount: {company.latestHeadcount}</div>,
        })),
      },
      filterFn: 'singleSelectFn' as FilterFnOption<MassiveCompany>,

      size: 300,
      sortingFn: 'stringFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => (
        <div
          className={`flex max-w-full items-center gap-3 truncate p-2`}
          onMouseEnter={() => {
            prefetchCompanyData(cell.row.original.companyId)
          }}>
          <InputCheckbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()} />
          {/* {cell.row.getCanExpand() ? (
            <button
              {...{
                onClick: cell.row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}>
              {cell.row.getIsExpanded() ? '👇' : '👉'}
            </button>
          ) : undefined} */}

          <CompanyAvatar
            logo={cell.row.original.companyS3LogoUrl}
            alt={cell.row.original.companyName}
            altLetter={cell.row.original.companyName[0].toUpperCase()}
          />

          <PluralLink href={`/company/${cell.row.original.companyId}`}>{cell.getValue() as string}</PluralLink>
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('companyDomain', {
      id: 'companyDomain',
      header: 'Domain',
      enableGlobalFilter: true,
      filterFn: 'stringFn' as FilterFnOption<MassiveCompany>,
      size: 300,
      sortingFn: 'stringFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('foundingDate', {
      id: 'foundingDate',
      header: 'Founding Date',
      sortingFn: 'dateFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'dateFn' as FilterFnOption<MassiveCompany>,
      size: 300,
      cell: (cell) => <div className={cellClassName}>{new Date(cell.getValue() as string).toLocaleDateString()}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('latestOpenRoleCount', {
      id: 'latestOpenRoleCount',
      header: 'Latest Open Role Count',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
    }) as ColumnDef<MassiveCompany>,

    columnHelper.accessor('latestHeadcount', {
      id: 'latestHeadcount',
      header: 'Latest Headcount',
      size: 300,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToReadableString(cell.getValue() as number) : undefined}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('location', {
      id: 'location',
      header: 'Location',
      size: 300,
      filterFn: 'stringFn' as FilterFnOption<MassiveCompany>,
      sortingFn: 'stringFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('cbCompanyDescription', {
      id: 'cbCompanyDescription',
      header: 'Company Description',
      // minSize: 400,
      filterFn: 'stringFn' as FilterFnOption<MassiveCompany>,
      size: 1000,
      maxSize: 3000,
      sortingFn: 'stringFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('sixMonthsOverallHeadcountValue', {
      header: '6 Months Overall Headcount',
      id: 'sixMonthsOverallHeadcountValue',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('sixMonthsOverallHeadcountGrowthRate', {
      header: '6 Months Headcount Growth Rate',
      id: 'sixMonthsOverallHeadcountGrowthRate',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,

      cell: (cell) => (
        <div className={`max-w-full truncate p-2 ${(cell.getValue() ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
        </div>
      ),
      meta: {
        filterEndAdornment: <div className="mr-4">%</div>,
      },
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('oneYearOverallHeadcountValue', {
      header: '1 Year Overall Headcount',
      id: 'oneYearOverallHeadcountValue',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('sixMonthsOverallHeadcountGrowthRate', {
      header: '6 month Headcount Line',
      id: 'sixMonthsOverallHeadcountGrowthRateSparkLine',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,

      cell: (cell) => (
        <div className={`max-w-full`}>
          <SparklineChart
            timeAgo={{
              years: 0,
              months: 6,
            }}
            color={(safeCastToNumber(cell.getValue()) ?? 0) > 0 ? 'green' : 'red'}
            scatterData={[
              {
                data: cell.row.original.allHeadcountStats.map((stat) => {
                  return {
                    x: new Date(stat.statsDate),
                    y: stat.count,
                  }
                }),
                label: 'Headcount',
              },
            ]}
          />
        </div>
      ),
      meta: {
        filterEndAdornment: <div className="mr-4">%</div>,
      },
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('oneYearOverallHeadcountGrowthRate', {
      header: '1 Year Headcount Growth Rate',
      id: 'oneYearOverallHeadcountGrowthRate',
      size: 250,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,

      cell: (cell) => (
        <div className={`max-w-full truncate p-2 ${(cell.getValue() ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
        </div>
      ),
      meta: {
        filterEndAdornment: <div className="mr-4">%</div>,
      },
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('oneYearOverallHeadcountGrowthRate', {
      header: '1 Year Headcount Line',
      id: 'oneYearOverallHeadcountGrowthRateSparkLine',
      size: 250,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => {
        return (
          <div className={`max-w-full`}>
            <SparklineChart
              timeAgo={{
                years: 1,
                months: 0,
              }}
              color={(safeCastToNumber(cell.getValue()) ?? 0) > 0 ? 'green' : 'red'}
              scatterData={[
                {
                  data: cell.row.original.allHeadcountStats.map((stat) => {
                    return {
                      x: new Date(stat.statsDate),
                      y: stat.count,
                    }
                  }),
                  label: 'Headcount',
                },
              ]}
            />
          </div>
        )
      },
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('twoYearsOverallHeadcountGrowthRate', {
      header: '2 Year Headcount Line',
      id: 'twoYearsOverallHeadcountGrowthRateSparkLine',
      size: 250,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      cell: (cell) => {
        return (
          <div className={`max-w-full`}>
            <SparklineChart
              timeAgo={{
                years: 2,
                months: 0,
              }}
              color={(safeCastToNumber(cell.getValue()) ?? 0) > 0 ? 'green' : 'red'}
              scatterData={[
                {
                  data: cell.row.original.allHeadcountStats.map((stat) => {
                    return {
                      x: new Date(stat.statsDate),
                      y: stat.count,
                    }
                  }),
                  label: 'Headcount',
                },
              ]}
            />
          </div>
        )
      },
    }) as ColumnDef<MassiveCompany>,

    columnHelper.accessor('twoYearsOverallHeadcountValue', {
      header: '2 Years Overall Headcount',
      id: 'twoYearsOverallHeadcountValue',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('twoYearsOverallHeadcountGrowthRate', {
      header: '2 Years Headcount Growth Rate',
      id: 'twoYearsOverallHeadcountGrowthRate',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,

      cell: (cell) => (
        <div className={`max-w-full truncate p-2 ${(cell.getValue() ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
        </div>
      ),
      meta: {
        filterEndAdornment: <div className="mr-4">%</div>,
      },
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastFundingRoundAmount', {
      header: 'Latest Funding Round Amount',
      id: 'lastFundingRoundAmount',
      size: 300,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastFundingRoundAnnouncementDate', {
      header: 'Latest Funding Round Announcement Date',
      id: 'lastFundingRoundAnnouncementDate',
      size: 300,
      sortingFn: 'dateFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'dateFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {isSome(cell.getValue()) ? new Date(cell.getValue() as Date).toLocaleDateString() : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastFundingRoundClosedDate', {
      header: 'Latest Funding Round Closed Date',
      id: 'lastFundingRoundClosedDate',
      size: 300,
      sortingFn: 'dateFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'dateFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? new Date(cell.getValue() as Date).toLocaleDateString() : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('createdAt', {
      header: 'Created at',
      id: 'createdAt',
      size: 200,
      sortingFn: 'dateFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'dateFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>{cell.getValue() ? new Date(cell.getValue()).toLocaleDateString() : ''}</div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('totalAmountRaised', {
      header: 'Total Amount Raised',
      id: 'totalAmountRaised',
      size: 150,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastRoundValuation', {
      header: 'Last Round Valuation',
      id: 'lastRoundValuation',
      size: 150,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastRoundPrice', {
      header: 'Last Round Price',
      id: 'lastRoundPrice',
      size: 150,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastKnownPrice', {
      header: 'Last Known Price',
      id: 'lastKnownPrice',
      size: 150,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('lastKnownValuation', {
      header: 'Last Known Valuation',
      id: 'lastKnownValuation',
      size: 150,
      sortingFn: 'numberFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'numberFn' as FilterFnOption<MassiveCompany>,
      cell: (cell) => (
        <div className={cellClassName}>
          {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
        </div>
      ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('isPublic', {
      header: 'Public',
      id: 'isPublic',
      size: 300,
      sortingFn: 'booleanFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'exclusiveSelectFn' as FilterFnOption<MassiveCompany>,
      meta: {
        inputType: 'boolean',
        singleSelectOptions: [
          {
            label: 'Public',
            value: 'true',
          },
          {
            label: 'Private',
            value: 'false',
          },
        ],
        updateRemote: async (row: any, newValue: Optional<boolean>) => {
          if (newValue == null) return
          await updatePublicMutation.mutateAsync({
            companyId: row.companyId,
            isPublic: newValue,
            adminEmail: user?.email ?? '',
          })
        },
      },
      cell: (cell) =>
        isEditor ? (
          defaultCellFn(cell)
        ) : (
          <div className={cellClassName}>{isSome(cell.getValue()) ? (cell.getValue() ? 'Public' : 'Private') : ''}</div>
        ),
    }) as ColumnDef<MassiveCompany>,
    columnHelper.accessor('operatingStatus', {
      header: 'Operating Status',
      id: 'operatingStatus',
      size: 300,
      sortingFn: 'booleanFn' as SortingFnOption<MassiveCompany>,
      filterFn: 'exclusiveSelectFn' as FilterFnOption<MassiveCompany>,
      meta: {
        options: ['Active', 'Closed', '-'],
        singleSelectOptions: [
          {
            label: 'Active',
            value: 'Active',
          },
          {
            label: 'Closed',
            value: 'Closed',
          },
        ],

        updateRemote: async (row: any, newValue: Optional<string>) => {
          if (!newValue || newValue === '-') {
            newValue = null
          }
          await updatePublicMutation.mutateAsync({
            companyId: row.companyId,
            operatingStatus: newValue,
            adminEmail: user?.email ?? '',
          })
        },
      },
      cell: (cell) => (isEditor ? defaultCellFn(cell) : <div className={cellClassName}>{cell.getValue()}</div>),
    }) as ColumnDef<MassiveCompany>,
  ]

  const { navigate, pushNavigate } = usePluralNavigation()
  let [chartOpen, setChartOpen] = useState(false)

  const [chartView, setChartView] = useState<'aggregateHeadcount' | 'individualHeadcount' | 'funding'>(
    'aggregateHeadcount',
  )
  let [selectedTime, setSelectedTime] = useChartTimeFooter({
    years: 2,
    months: 0,
  })

  return (
    <>
      <SheetPage
        paginate
        closedChartSize={57}
        createChart={(rows: Row<unknown>[]) => {
          let individualDataSets = {} as {
            [key: string]: {
              x: Date
              y: number
            }[]
          }

          let fundingDataMap = {} as {
            [key: string]: {
              x: Date
              y: number
            }
          }

            ; (rows as Row<MassiveCompany>[])
              .map((row) => row.original)
              .forEach((company) => {
                company.fundingRounds
                  .filter(
                    (round) => (round.closedDate || round.announcedDate) && (round.closedTotal || round.announcedTotal),
                  )
                  .forEach((round) => {
                    let date = new Date((round.closedDate || round.announcedDate)!)
                    let dateKey = `${date.getMonth() + 1}/1/${date.getFullYear()}`
                    let existingData = fundingDataMap[dateKey]
                    if (!existingData?.y) {
                      fundingDataMap[dateKey] = {
                        y: safeCastToNumber(round.closedTotal || round.announcedTotal)!,
                        x: new Date(dateKey),
                      }
                    } else {
                      fundingDataMap[dateKey] = {
                        y:
                          fundingDataMap[dateKey].y +
                          safeCastToNumber(round.closedTotal || round.announcedTotal || round.estimatedAmount)!,
                        x: fundingDataMap[dateKey].x,
                      }
                    }
                  })
              })

          const dataPointCountPerDate = new Map<string, number>()
          const companyCount = rows.length
          for (let row of rows as Row<MassiveCompany>[]) {
            let company = row.original
            for (let point of company.allHeadcountStats) {
              let dateKey = point.statsDate
              if (dataPointCountPerDate.has(dateKey)) {
                dataPointCountPerDate.set(dateKey, dataPointCountPerDate.get(dateKey)! + 1)
              } else {
                dataPointCountPerDate.set(dateKey, 1)
              }
            }
          }
          const tolerancePercentage = 10
          const lowerBound = companyCount - (companyCount * tolerancePercentage) / 100
          const upperBound = companyCount + (companyCount * tolerancePercentage) / 100

          let dataSetMap = (rows as Row<MassiveCompany>[]).reduce(
            (acc, row) => {
              let company = row.original
              individualDataSets[company.companyName] = company.allHeadcountStats.map((stat) => {
                return {
                  x: new Date(stat.statsDate),
                  y: stat.adjustedCount,
                }
              })
              let headcountStats = company.allHeadcountStats
              headcountStats.forEach((stat) => {
                if (
                  dataPointCountPerDate.has(stat.statsDate) &&
                  (dataPointCountPerDate.get(stat.statsDate)! < lowerBound ||
                    dataPointCountPerDate.get(stat.statsDate)! > upperBound)
                ) {
                  return
                }
                let date = new Date(stat.statsDate)

                let dateKey = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
                let existingData = acc[dateKey]

                if (!existingData?.y) {
                  acc[dateKey] = {
                    y: stat.adjustedCount,
                    x: new Date(stat.statsDate),
                  }
                } else {
                  acc[dateKey] = {
                    y: existingData.y + stat.adjustedCount,
                    x: new Date(stat.statsDate),
                  }
                }
              })
              return acc
            },
            {} as {
              [key: string]: { x: Date; y: number }
            },
          )

          // const colors: string[] = []

          // for (let i = 0; i < 30; i++) {
          //   const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
          //   colors.push(randomColor)
          // }

          const colors = [
            '#1f77b4',
            '#ff7f0e',
            '#2ca02c',
            '#d62728',
            '#9467bd',
            '#8c564b',
            '#e377c2',
            '#7f7f7f',
            '#bcbd22',
            '#17becf',
            '#aec7e8',
            '#ffbb78',
            '#98df8a',
            '#ff9896',
            '#c5b0d5',
            '#c49c94',
            '#f7b6d2',
            '#c7c7c7',
            '#dbdb8d',
            '#9edae5',
            '#393b79',
            '#637939',
            '#8c6d31',
            '#843c39',
            '#7b4173',
            '#3182bd',
            '#e6550d',
            '#31a354',
            '#756bb1',
            '#636363',
            '#9c9ede',
            '#d6616b',
            '#ce6dbd',
            '#e7969c',
            '#ff9896',
            '#bdbdbd',
            '#e7ba52',
            '#17becf',
            '#7b6888',
            '#6b486b',
            '#a05d56',
            '#d0743c',
            '#ff8c00',
            '#ffbb78',
            '#98df8a',
            '#ff9896',
            '#c5b0d5',
            '#c49c94',
            '#f7b6d2',
            '#c7c7c7',
          ]

          return (
            <div className={'border-b'}>
              <div
                className="flex h-[55px] w-full cursor-pointer items-center gap-2  border-t pl-8 font-semibold"
                onClick={() => {
                  setChartOpen((chartOpen) => !chartOpen)
                }}>
                {chartOpen ? 'Hide' : 'Show'} chart{' '}
                {chartOpen ? <CaretUpIcon className="h-[15px]" /> : <CaretDownIcon className="h-[15px]" />}
              </div>
              {chartOpen ? (
                <div>
                  <CompanyStatChart2
                    timeAgo={selectedTime}
                    yAxisLabel={chartView === 'funding' ? 'Amount raised / month' : 'Headcount'}
                    chartName={chartView === 'funding' ? 'Amount raised / month' : 'Headcount'}
                    rightLabel={chartView === 'individualHeadcount'}
                    tickFormatter={(value) => numberToAbbreviatedString(value)}
                    scatterData={
                      chartView === 'funding'
                        ? [
                          {
                            data: [...Object.values(fundingDataMap).sort((a, b) => a.x.getTime() - b.x.getTime())],
                            label: 'Amount Raised',
                            tickFormatter: (value) => numberToAbbreviatedDollarString(value),
                            stroke: 'black',
                          },
                        ]
                        : rows.length > 100 || chartView === 'aggregateHeadcount'
                          ? [
                            {
                              data: Object.values(dataSetMap).sort((a, b) => a.x.getTime() - b.x.getTime()),
                              label: 'Headcount',
                              stroke: '#00FFFF',
                              tickFormatter: (value) => numberToReadableString(value),
                            },
                          ]
                          : [
                            ...Object.entries(individualDataSets).map(([key, value], index) => {
                              return {
                                data: value,
                                label: key,
                                stroke: colors[index % colors.length],
                                tickFormatter: (val: any) => numberToReadableString(val),
                              }
                            }),
                          ]
                    }
                  />
                  <div className="flex w-full flex-row items-center">
                    <CompanyStatChartTimeFooter selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                    <div className={`ml-auto mr-[80px] w-[200px] flex-row p-2`}>
                      <AppSelectFormfield
                        options={[
                          { value: 'funding', label: 'Funding' },
                          { value: 'aggregateHeadcount', label: 'Aggregate Headcount' },
                          ...(rows.length > 100
                            ? []
                            : [{ value: 'individualHeadcount', label: 'Individual Headcount' }]),
                        ]}
                        onValueChange={(val) => {
                          setChartView(val as 'funding' | 'aggregateHeadcount' | 'individualHeadcount')
                        }}
                        value={
                          rows.length > 100 && chartView === 'individualHeadcount' ? 'aggregateHeadcount' : chartView
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        }}
        defaultSheetId={1}
        createFloatingFooterButtons={createFloatingFooterButtons}
        backBreadcrumb={{ href: '/dashboard/sheets/company', title: 'Companies' }}
        getRowId={(row: any) => row.companyId}
        isLoading={companiesQuery.isLoading || !!props.isLoading}
        columns={columns as ColumnDef<unknown>[]}
        data={companies}
        filterFns={{}}
        sortingFns={{}}
        onNavigate={(newId) => {
          navigate(`/dashboard/sheets/company/${newId}`)
        }}
        onNavigatePush={(newId) => {
          pushNavigate(`/dashboard/sheets/company/${newId}`)
        }}
      />
    </>
  )
}

function parsePercentage(value: string): number | undefined {
  if (value.endsWith('%')) {
    const numberPart = parseFloat(value.slice(0, -1))
    return isNaN(numberPart) ? undefined : numberPart
  }
  return undefined
}

export function FloatingFooter(
  props: PropsWithChildren<{
    numberSelected: number
  }>,
) {
  return (
    <>
      {props.numberSelected > 0 ? (
        <div className="fixed bottom-[40px] right-[50%] z-50 flex translate-x-[50%] transform flex-row items-center gap-3 rounded-lg bg-white px-4 py-2  shadow-md">
          <div className="flex flex-row items-center gap-1 ">
            <div className="rounded bg-blue-300 px-2 text-white">{props.numberSelected}</div>
            <div className="text-sm text-gray-500">selected</div>
          </div>
          <div className="flex items-center gap-3">{props.children}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export function CompanyAvatar(props: { logo: string | undefined; alt: string; altLetter: string; size?: number }) {
  const size = props.size ?? 22
  return props.logo ? (
    <img
      src={props.logo}
      style={{
        minWidth: size,
        minHeight: size,
      }}
      alt={props.alt}
      className={`h-[${size}px] w-[${size}px] shrink-0  rounded object-contain`}
    />
  ) : (
    <div
      style={{
        minWidth: size,
        minHeight: size,
      }}
      className={`text-md h-[${size}px] w-[${size}px] flex shrink-0 items-center justify-center rounded bg-gray-200 object-contain font-bold text-gray-500`}>
      {props.altLetter}
    </div>
  )
}
